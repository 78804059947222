import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faPhone,
    faEnvelope,
} from "@fortawesome/free-solid-svg-icons"

const Footer = () => (
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        Camping <strong>Silveira</strong> - Contacts: &nbsp;
        <a href="mailto:campingsilveira@gmail.com">
          <FontAwesomeIcon icon={faEnvelope} size="s" />
          &nbsp;campingsilveira@gmail.com
        </a>
        &nbsp; or &nbsp; <FontAwesomeIcon icon={faPhone}/>+351 917 741 173 (+351 962 387 266)
      </p>
    </div>
  </footer>
)

export default Footer
